import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import ProgressbarComponent from '@/core/shared/progressbar/progressbar.component.vue';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import {loginStateMutations} from '@/pages/login/store/store/mutations';


@Options({
    name: 'steps-card-component',
    components: {
        ProgressbarComponent,
    },
    props: {
        current: {
            type: Number,
            require: true,
        },
        step: {
            type: Number,
            require: true,
        },
        stepDescription: {
            type: Array,
            require: true,
        },
        imeiInfo: {
            type: Object,
            require: true,
        },
        error: {
            type: Object,
            require: true,
        },
        formError: {
            type: Object,
            require: true,
        },
        userData: {
            type: Object,
            require: false,
        },
        formRegistrationData: {
            type: Object,
            require: false,
        },
        price: {
            type: Number,
            require: false,
        },
        documentTypes: {
            type: Array,
            require: true,
        },
        documentGroups: {
            type: Array,
            require: true,
        }
    },

})

export default class StepsCardComponent extends Vue {

    public store = store;
    public step: Number = 1;
    public current: Number = 1;
    public stepDescription: Array<object> = [];
    public locale$: any = locale;
    public locale: any = null;
    public selectDocumentType = 'AZE';
    public pruningFin = 7;
    public pruningDocNumber = 8;
    public formRegistrationData = {
        documentType: '1',
        documentNumber: '',
        fin: '',
        phone: '+994(',
        documentTypeNumber: 1,
    };
    public phone = '+994(';

    public imeiInfo = {
        imei: {
            first: '',
            second: '',
        },
        simCount: 1,
    };
    // validation error
    public error = {
        imei: {
            first: false,
            second: false,
        },
    };
    public formError = {
        fin: false,
        document: false,
        tel: false
    };

    public documentType = [
        {
            id: 'AZE',
            value: 'AZE'
        },
        {
            id: 'AA',
            value: 'AA'
        },
        // {
        //     id: 'AB',
        //     value: 'AB'
        // }
    ];


    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.services) {
                this.locale = res.services.specialService;
            }
        });
    }

    public selectedDocumentType(info: any) {
        this.store.commit('SET_DOCUMENT_TYPE_NUMBER', info);
        if (this.formRegistrationData.documentTypeNumber === 1) {
            this.pruningDocNumber = 8;
        } else {
            this.pruningDocNumber = 7;
        }
        this.pruningFin = 7;
        // if (this.formRegistrationData.documentTypeNumber===1 || this.formRegistrationData.documentTypeNumber===4 ) {
        //     this.pruningFin = 7;
        // }
        // if (this.formRegistrationData.documentTypeNumber === 3) {
        //     this.pruningFin = 7;
        // }
        // if (this.formRegistrationData.documentTypeNumber === 2) {
        //     this.pruningFin = 6;
        // }
        this.documentValidation();
        this.finValidation();
    }

    public selectedDocumentGroup(info: any) {
        this.$emit('selectDocumentGroup', info);
        this.store.commit('SET_IMEI_DOCUMENT_GROUP', info);
    }

    public changeRadioSim(): void {
        if (this.imeiInfo.simCount === 1) {
            this.store.commit('SET_IMEI_SECOND', '');
            this.error.imei.second = false;
        }
    }

    public documentValidation(): void {
        if (this.formRegistrationData.documentNumber) {
            this.store.commit('SET_IMEI_DOCUMENT',
                this.formRegistrationData.documentNumber.replace(/[^0-9]+/g, '').slice(0, this.pruningDocNumber));
        }
    }

    public phoneValidation(): void {
        if (this.phone) {
            this.store.commit('SET_IMEI_PHONE',
                this.formRegistrationData.phone);
            // this.formRegistrationData.phone.replace(/[^0-9]+/g, '').slice(3));
        }
    }

    public deleteNumber(event: any) {
        if ((event.keyCode === 8) || (event.keyCode === 46)) {
            if (this.phone.length <= 5) {
                this.store.commit('SET_IMEI_PHONE',
                    `+994( `);
            }
        }
    }

    public finValidation(): void {
        if (this.formRegistrationData.fin) {
            this.store.commit('SET_IMEI_FIN',
                this.formRegistrationData.fin.replace(/[^A-Na-n0-9P-Zp-z]/, '').toUpperCase().slice(0, this.pruningFin));
        }
    }

    public checkInput(id: any): void {
        if (id === 1) {
            this.store.commit('SET_IMEI_FIRST',
                this.imeiInfo.imei.first.replace(/[^0-9]+/g, '').slice(0, 15));
            if (this.imeiInfo.imei.first) {
                this.error.imei.first = false;
            }
        } else {
            this.store.commit('SET_IMEI_SECOND',
                this.imeiInfo.imei.second.replace(/[^0-9]+/g, '').slice(0, 15));
            if (this.imeiInfo.imei.second) {
                this.error.imei.second = false;
            }
        }
    }

}
