export class PostAddAppealFileModel {
    public appealNumber: string | null = null;
    public files: File[] | null = [];

    constructor(appealNumber: any, files: any) {
        this._setAppealNumber(appealNumber);
        this._setFiles(files);
    }

    private _setAppealNumber(appealNumber: any) {
        this.appealNumber = appealNumber;
    }

    private _setFiles(files: any) {
        for (let key in files) {
            this.files?.push(files[0]);
        }
    }
}