import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import {PersonalInformationForm} from '@/pages/permission-movement-service/types/personal-information-form';

import '@vuepic/vue-datepicker/dist/main.css';
import {az} from 'date-fns/locale';
import Datepicker from 'vue3-datepicker';

@Options({
    name: 'personal-information-form-component',
    props: {
        documentSeriesType: {
            type: Array, require: true
        },
        personalInformation: {
            type: Object,
            require: true
        },
        citizenshipValue: {
            type: Number, require: true
        },
        organizationLegalFormValue: {
            type: Number, require: true
        },
        legalPersonCarrier: {
            type: Boolean, require: true
        },
        errorPersonalInformation: {
            type: Object, require: true
        }
    },
    components: {
        Datepicker
    },


})

export default class PersonalInformationFormComponent extends Vue {
    public date = null;
    public pruningDocumentNumber = 0;
    public dateLimit = new Date();
    public dataLang = az;
    public personalInformation: PersonalInformationForm = {
        documentNumber: null,
        documentTypeNumber: null,
        fin: null,
        phone: '+994(',
        email: null,
        tin: null,
        organization: null,
        position: null,
        dateOfBirth: null,
        name: null,
        surname: null,
        passportSeriesAndNumber: null,
        gender: null,
        bloodGroup: null,
        address: null,
        carrierName: null,
        carrierPhone: null,
        carrierAddress: null,
        documentTypeResident: 'MY',
        documentTypeResidentNumber: null,
        finResident: null
    };
    public locale$: any = locale;
    public locale: any = null;
    public store = store;
    public pruningFin = 8;
    public documentTypeResident = [
        {
            id: 'MY',
            value: 'MY'
        },
    ];


    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.permissionMovementService) {
                this.locale = res.permissionMovementService;
            }
        });
    }


    public selectedDocumentType(info: any) {
        let payload = {
            type: 'documentTypeNumber',
            value: info
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);

        this.validationDocument(info);
        if (this.personalInformation.documentNumber) {
            this.documentNumberChanged(this.personalInformation.documentNumber, 'documentNumber');
        }
        if (this.personalInformation.fin) {
            this.finChanged(this.personalInformation.fin, 'fin');
        }
    }

    public validationDocument(info: any) {
        if (info === 1) {
            this.pruningFin = 7;
            this.pruningDocumentNumber = 8;
        }
        if (info === 2) {
            this.pruningFin = 7;
            this.pruningDocumentNumber = 7;
        }
        if (info === 3) {
            this.pruningFin = 5;
            this.pruningDocumentNumber = 7;
        }
        if (info === 4) {
            this.pruningFin = 6;
            this.pruningDocumentNumber = 7;
        }

    }

    public selectedDocumentTypeResident(info: any) {
        let payload = {
            type: 'documentTypeResident',
            value: info
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);

    }

    public documentNumberChanged(event: any, name: any) {
        this.validationDocument(this.personalInformation.documentTypeNumber);
        let payload = {
            type: name,
            value: event.replace(/[^0-9]+/g, '').slice(0, this.pruningDocumentNumber)
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);
    }

    public finChanged(event: any, name: any, count: any = 7) {
        let payload = {
            type: 'fin',
            value: event.replace(/[^A-Na-n0-9P-Zp-z]/, '').toUpperCase().slice(0, this.pruningFin)
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);
    }

    public passwordSeriesChanged(event: any, name: any, count: any = 7) {
        let payload = {
            type: name,
            value: event.replace(/[^A-Na-n0-9P-Zp-z]/, '').toUpperCase()
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);
    }

    public inputChanged(event: any, name: any) {
        let payload = {
            type: name,
            value: event
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);
    }

    public phoneChanged(event: any, name: any) {
        let payload = {
            type: name,
            value: event
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);
    }

    public emailChanged(event: any) {
        let payload = {
            type: 'email',
            value: event
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);
    }

    public tinChanged(event: any) {
        let payload = {
            type: 'voen',
            value: event.replace(/[^0-9]+/g, '').slice(0, 10)
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);
    }

    public addressChanged(event: any, name: any) {
        let payload = {
            type: name,
            value: event
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);
    }

    public carrierNameChanged(event: any) {
        let payload = {
            type: 'carrierName',
            value: event
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);
    }

    public carrierPhoneChanged(event: any) {
        let payload = {
            type: 'carrierPhone',
            value: event
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);
    }

    public organizationChanged(event: any) {
        let payload = {
            type: 'organization',
            value: event
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);
    }

    public positionChanged(event: any) {
        let payload = {
            type: 'position',
            value: event
        };
        this.store.commit('SET_PERSONAL_ORGANIZATION', payload);
    }

    public deleteNumber(event: any, name: any) {
        if ((event.keyCode === 8) || (event.keyCode === 46)) {
            if (this.personalInformation[`${name}`]) {
                if (this.personalInformation[`${name}`].length <= 5) {
                    let payload = {
                        type: name,
                        value: '+994('
                    };
                    this.store.commit('SET_PERSONAL_ORGANIZATION', payload);
                }
            }
        }
    }

    public selectDate() {
    }

}
