import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import {IUser} from '@/core/auth/store/state';

@Options({
    name: 'general-information-form-component',
    props: {
        citizenshipValue: {
            type: Number, require: true
        },
        organizationLegalFormValue: {
            type: Number, require: true
        },
        legalPersonCarrier: {
            type: Boolean, require: true
        },
        citizenship: {
            type: Array, require: true
        },
        organizationLegalForm: {
            type: Array, require: true
        },
        userData: {
            type: Object, require: true
        }
    },
    inject: ['stepOneValidation'],
    watch: {
        legalPersonCarrier: {
            handler: 'changeLegalPersonCarrier',
            immediate: false,
            deep: false,
        }
    }

})

export default class GeneralInformationFormComponent extends Vue {
    public organizationLegalFormValue: number | null = null;
    public locale$: any = locale;
    public locale: any = null;
    public store = store;
    public personCarrier = true;
    public legalPersonCarrierValue: boolean | null = true;
    public legalPersonCarrier = false;
    public userData: IUser = {
        name: '',
        surname: '',
        fatherName: '',
        pin: '',
        birthDate: '',
        image: '',
        address: '',
        birthAddress: null,
        document: '',
        documentType: '',
        documentNumber: '',
        citizenshipId: null,
        dateOfIssue: '',
        registeredDepartmentName: '',
        loginType: '',
        certificateNumber: '',
        tin: '',
        structureName: '',
        entity: '',
    };

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.permissionMovementService) {
                this.locale = res.permissionMovementService;
            }
        });
    }

    public mounted() {
        this.legalPersonCarrierValue = this.legalPersonCarrier;
    }

    public changeLegalPersonCarrier(val: any) {
        this.legalPersonCarrierValue = val;
    }

    public selectCitizenship(info: any) {
        this.store.commit('SET_CITIZENSHIP_VALUE', info);
        this.store.commit('SET_RESET_PERMISSION');
        if (this.userData.document?.length && info !== 1) {
            this.store.commit('SET_ORGANIZATION_LEGAL_FORM_VALUE', 2);
            this.store.commit('SET_PERSON_CARRIER', true);
        }
    }

    public selectOrganizationLegalForm(info: any) {
        this.store.commit('SET_ORGANIZATION_LEGAL_FORM_VALUE', info);
        this.store.commit('SET_RESET_PERMISSION', this.userData);
        if (info === 2 || info === 3) {
            this.store.commit('SET_PERSON_CARRIER', true);
            this.legalPersonCarrierValue = true;
        } else {
            this.store.commit('SET_PERSON_CARRIER', null);
            this.legalPersonCarrierValue = null;
        }
    }

    public selectPersonCarrier(data: any) {
        this.store.commit('SET_PERSON_CARRIER', data);
    }


}