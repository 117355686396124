import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';

@Options({
    name: 'vehicle-parameters-load-form-component',
    props: {
        vehicleParametersLoad: {
            type: Object,
            require: true
        },
        errorVehicleParametersLoad: {
            type: Object, require: true
        }
    }
})

export default class VehicleParametersLoadFormComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public store = store;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.permissionMovementService) {
                this.locale = res.permissionMovementService;
            }
        });
    }

    public inputChanged(event: any, name: any, parent: any, count: any) {
        let payload = {
            parent: parent,
            type: name,
            value: event.replace(/[^0-9,.]/g, '').slice(0, count)
        };
        this.store.commit('SET_VEHICLE_PARAMETERS_LOAD', payload);
    }
}