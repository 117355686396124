<style src="./steps-card.component.scss" lang="scss"></style>

<template>
  <div class="steps-card">
    <template v-for="(item,index) in stepDescription" :key="index">
      <div class="steps-card__title" v-if="current === index+1">
        <span class="steps-card__number d-block d-md-none">{{index+1}}</span>
        <span> {{ item.description }}</span>
      </div>
    </template>
    <progressbar-component  :step="step" :current="current" :stepDescription="stepDescription"/>
    <form class="form">
      <section v-if="current === 1">
        <div class="steps-card__text">
          {{ locale.desc }}
        </div>
      </section>
      <section v-if="current === 2">
        <div class="row">
          <div class="col-md-6 col-12 col-lg-4">
            <div class="form__wrapper d-md-flex">
              <div class="form__group d-flex align-start">
                <input
                    @change="changeRadioSim"
                    id="single" type="radio" class="v-radio v-radio--circle v-radio--dark-blue mr-12" :value="1"
                    v-model="imeiInfo.simCount"/>
                <div class="form-form__group-wrapper">
                  <label for="single" class="v-label v-label--bold">Single</label>
                  <div class="form__group-info">
                    {{ locale.single }}
                  </div>
                </div>
              </div>
              <div class="form__group d-flex align-start">
                <input
                    type="radio" id="duos"
                    class="v-radio v-radio--circle v-radio--dark-blue mr-12" :value="2"
                    v-model="imeiInfo.simCount"/>
                <div class="form-form__group-wrapper">
                  <label for="duos" class="v-label v-label--bold">Duos</label>
                  <div class="form__group-info">
                    {{ locale.duos }}
                  </div>
                </div>
              </div>
            </div>

            <div class="form__group mb-16">
              <label
                  :class="{'v-label--error':error.imei.first}"
                  class="v-label v-label--thin">IMEI 1</label>
              <input
                  @input="checkInput(1)"
                  :class="{'v-input--error':error.imei.first}"
                  type="text" class="v-input" v-model="imeiInfo.imei.first">

              <div class="form__group-info mt-12 ml-20">
                {{ locale.enterCode }}

              </div>
            </div>
            <div class="form__group  mb-16" v-if='imeiInfo.simCount === 2'>
              <label
                  :class="{'v-label--error':error.imei.second}"
                  class="v-label v-label__thin">IMEI 2</label>
              <input
                  @input="checkInput(2)"
                  :class="{'v-input--error':error.imei.second}"
                  type="text" class="v-input" v-model="imeiInfo.imei.second">
              <div class="form__group-info mt-12 ml-20">
                {{ locale.enterCode }}

              </div>
            </div>
          </div>
        </div>
      </section>
      <section v-if="current === 3">
        <div class="row">
          <div class="col-md-4 col-12" v-if="!userData?.name">
            <div class="row">
              <div class="col-12">
                <div class="form__group mb-20">
                  <label class="v-label v-label--thin">
                    Vətəndaşlıq
                  </label>
                  <v-select
                      class="mt-16"
                      @select="selectedDocumentGroup"
                      placeholder="AZE"
                      :options="documentGroups"
                      :value="formRegistrationData.documentGroup"
                  />
<!--                  <div class="v-select">-->
<!--                    <select-->
<!--                        v-model="formRegistrationData.documentType"-->
<!--                        disabled-->
<!--                        name="docType" id="docType" class="v-select">-->
<!--                      <option value="1" selected>{{ locale.card }}</option>-->
<!--                    </select>-->
<!--                  </div>-->
                </div>
              </div>
              <div class="col-5 col-md-4  pr-0">
                <div class="form__group mb-24">
                  <label
                      for="docNum" class="v-label v-label--thin"> Seriya</label>
                  <v-select
                      class="mt-16"
                      ref="selectPrefix"
                      @select="selectedDocumentType"
                      placeholder="AZE"
                      :options="documentTypes"
                      :value="formRegistrationData.documentTypeNumber"
                  />
                </div>
              </div>
              <div class="col-7 col-md-8">
                <div class="form__group">
                  <label
                      :class="{'v-label--error':formError.document}"
                      for="docNum" class="v-label v-label--thin">Sənədin nömrəsi</label>
                  <input v-model="formRegistrationData.documentNumber"
                         :class="{'v-input--error':formError.document}"
                         id="docNum"
                         @input="documentValidation"
                         class="v-input" type="text"
                         placeholder="XXXXXX"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-12">
            <div class="form__group mb-20" v-if="!userData?.name">
              <label
                  :class="{'v-label--error':formError.fin}"
                  for="date" class="v-label v-label--thin">{{ locale.fin }} </label>
              <input
                  :class="{'v-input--error':formError.fin}"
                  @input="finValidation"
                  v-model="formRegistrationData.fin"
                  id="date" type="text" placeholder="1234ABC" class="v-input">
            </div>
            <div class="form__group">
              <label

                  :class="{'v-label--error':formError.tel}"
                  for="tel" class="v-label v-label--thin">
                {{ locale.phone }}
              </label>
              <input
                  @keyup="deleteNumber($event)"
                  @keydown="deleteNumber($event)"
                  v-mask="['+994(##)-###-##-##', '+994(##)-###-##-##']"
                  :class="{'v-input--error':formError.tel}"
                  v-model="formRegistrationData.phone"
                  @input="phoneValidation"
                  id="tel" placeholder="+994(" class="v-input"/>
            </div>
          </div>
        </div>
      </section>
      <section v-if="current === 4">
        <div class="row">
          <div class="col-md-5 col-12">
            <div class="info_price mb-31">
              <img class="mr-17" src="@/assets/images/icons/imei-result.svg" alt="">
              {{ locale.tax }}: <strong> {{ price }} AZN </strong>
            </div>
            <div class="info__wrapper">
              <div class="info-item">
                <div class="steps-card__text">
                  IMEI 1 :
                </div>
                <div class="steps-card__text-bold">
                  {{ imeiInfo.imei.first }}
                </div>
              </div>
              <div class="info-item" v-if="imeiInfo.simCount ===2">
                <div class="steps-card__text">
                  IMEI 2 :
                </div>
                <div class="steps-card__text-bold">
                  {{ imeiInfo.imei.second }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  </div>
</template>

<script src="./steps-card.component.ts"></script>
