import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import ProgressbarComponent from '@/core/shared/progressbar/progressbar.component.vue';
import DocumentsFormComponent
    from '@/pages/permission-movement-service/components/documents-form/documents-form.component.vue';
import GeneralInformationFormComponent
    from '@/pages/permission-movement-service/components/general-information-form/general-information-form.component.vue';
import PersonalInformationFormComponent
    from '@/pages/permission-movement-service/components/personal-information-form/personal-information-form.component.vue';
import VehicleParametersFormComponent
    from '@/pages/permission-movement-service/components/vehicle-parameters-form/vehicle-parameters-form.component.vue';
import VehicleParametersLoadFormComponent
    from '@/pages/permission-movement-service/components/vehicle-parameters-load-form/vehicle-parameters-load-form.component.vue';

@Options({
    name: 'step-permission-component',
    components: {
        ProgressbarComponent,
        DocumentsFormComponent,
        GeneralInformationFormComponent,
        PersonalInformationFormComponent,
        VehicleParametersFormComponent,
        VehicleParametersLoadFormComponent
    },
    props: {
        userData:{
            type: Object, require: true
        },
        trailerVehicleType:{
            type: Array, require: true
        },
        documentSeriesType:{
            type: Array, require: true
        },
        organizationLegalForm :{
            type: Array, require: true
        },
        citizenship:{
            type: Array, require: true
        },
        stepDescription: {
            type: Array,
            require: true
        },
        current: {
            type: Number, require: true
        },
        step: {
            type: Number, require: true
        },
        personalInformation: {
            type: Object, require: true
        },
        citizenshipValue: {
            type: Number, require: true
        },
        organizationLegalFormValue: {
            type: Number, require: true
        },
        legalPersonCarrier: {
            type: Boolean, require: true
        },
        vehicleParameters: {
            type: Object, require: true
        },
        errorPersonalInformation: {
            type: Object, require: true
        },
        vehicleParametersLoad: {
            type: Object, require: true
        },
        errorTruckData: {
            type: Object, require: true
        },
        errorTrailerInformation: {
            type: Object, require: true
        },
        errorFreightAndShippingInformation: {
            type: Object, require: true
        },
        errorVehicleParametersLoad: {
            type: Object, require: true
        }
    }
})

export default class StepPermissionComponent extends Vue {
    public addTrailer() {
        this.$emit('addTrailer');
    }

    public deleteTrailer(info: any) {
        this.$emit('deleteTrailer', info);
    }

}
