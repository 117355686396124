

export class PostAddAppealModel {
    public name: string | null = null;
    public surname: string | null = null;
    public birthdate: Date | null | any = null;
    public citizenshipId: number | null = null;
    public address: string | null = null;
    public documentSeriesTypeId: number | null = null;
    public documentNumber: string | null = null;
    public pin: string | null = null;
    public organPersonTypeId: number | null = null;
    public companyName: string | null = null;
    public companyTaxNumber: string | null = null;
    public position: string | null = null;
    public phoneNumber: string | null = null;
    public email: string | null = null;
    public isCarrierSame: boolean | null = null;
    public carrierName: string | null = null;
    public carrierPhone: string | null = null;
    public carrierAddress: string | null = null;

    constructor(form: any, citizenshipId: any, organPersonTypeId: any, isCarrierSame: any) {
        this._setName(form);
        this._setSurname(form);
        this._setBirthdate(form);
        this._setCitizenshipId(citizenshipId);
        this._setAddress(form);
        this._setDocumentSeriesTypeId(form);
        this._setDocumentNumber(form);
        this._setPin(form);
        this._setOrganPersonTypeId(organPersonTypeId);
        this._setCompanyName(form);
        this._setCompanyTaxNumber(form);
        this._setPosition(form);
        this._setPhoneNumber(form);
        this._setEmail(form);
        this._setIsCarrierSame(citizenshipId, organPersonTypeId, isCarrierSame);
        this._setCarrierName(form);
        this._setCarrierPhone(form, citizenshipId, organPersonTypeId, isCarrierSame);
        this._setCarrierAddress(form);

    }

    private _setName({name}: any) {
        this.name = name;
    }

    private _setSurname({surname}: any) {
        this.surname = surname;
    }

    private _setBirthdate({dateOfBirth}: any) {
        if(dateOfBirth) {

        }
        let date = new Date(dateOfBirth);
        date.setHours(date.getHours()+4);
        this.birthdate = date;
    }

    private _setAddress({address}: any) {
        this.address = address;
    }

    private _setCitizenshipId(citizenshipId: any) {
        this.citizenshipId = citizenshipId;
    }

    private _setDocumentSeriesTypeId({documentTypeNumber}: any) {
        this.documentSeriesTypeId = documentTypeNumber;
    }

    private _setDocumentNumber({documentNumber}: any) {
        this.documentNumber = documentNumber;
    }

    private _setPin({fin}: any) {
        this.pin = fin;
    }

    private _setOrganPersonTypeId(isCarrierSame: any) {
        this.organPersonTypeId = isCarrierSame;
    }

    private _setCompanyName({organization}: any) {
        this.companyName = organization;
    }

    private _setCompanyTaxNumber({tin}: any) {
        this.companyTaxNumber = tin;
    }

    private _setPosition({position}: any) {
        this.position = position;
    }

    private _setPhoneNumber({phone}: any) {
        this.phoneNumber = phone;
    }

    private _setEmail({email}: any) {
        this.email = email;
    }

    private _setIsCarrierSame(citizenshipId: any, organPersonTypeId: any, isCarrierSame: any) {
        if (citizenshipId === 1 && organPersonTypeId === 1) {
            this.isCarrierSame = null;
        } else {
            this.isCarrierSame = isCarrierSame;
        }
    }

    private _setCarrierName({carrierName}: any) {
        this.carrierName = carrierName;
    }

    private _setCarrierPhone({carrierPhone}: any, citizenshipId: any, organPersonTypeId: any, isCarrierSame: any) {
        if (citizenshipId === 1 && organPersonTypeId === 1) {
            this.carrierPhone = null;
        } else {
            if (!isCarrierSame) {
                this.carrierPhone = carrierPhone;
            }
        }
    }

    private _setCarrierAddress({carrierAddress}: any) {
        this.carrierAddress = carrierAddress;
    }
}