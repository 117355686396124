import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import FileInputComponent from '@/core/shared/file-input/file-input.component.vue';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';

@Options({
    name: 'documents-form-component',
    components: {
        FileInputComponent
    },

})

export default class DocumentsFormComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public file = '';
    public store = store;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.permissionMovementService) {
                this.locale = res.permissionMovementService;
            }
        });
    }

    public selectFile(info: any) {
        this.store.commit('SET_DOCUMENT', info);

    }
}
