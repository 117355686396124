<style src="./step-permission.component.scss" lang="scss"></style>

<template>
  <div class="step-permission">
    <template v-for="(item,index) in stepDescription" :key="index">
      <div class="steps-card__title" v-if="current === index+1">
        <span class="steps-card__number d-block d-md-none">{{ index + 1 }}</span>
        <span> {{ item.description }}</span>
      </div>
    </template>
    <progressbar-component :step="step" :current="current" :stepDescription="stepDescription"/>
    <section v-if="current === 1">
      <general-information-form-component
          :userData="userData"
          :organizationLegalForm="organizationLegalForm"
          :citizenship="citizenship"
          :organizationLegalFormValue="organizationLegalFormValue"
          :legalPersonCarrier="legalPersonCarrier"
          :citizenshipValue="citizenshipValue"
      />
    </section>
    <section v-if="current === 2">
      <personal-information-form-component
          :documentSeriesType="documentSeriesType"
          :errorPersonalInformation="errorPersonalInformation"
          :organizationLegalFormValue="organizationLegalFormValue"
          :legalPersonCarrier="legalPersonCarrier"
          :citizenshipValue="citizenshipValue"
          :personalInformation="personalInformation"/>
    </section>
    <section v-if="current === 3">
      <vehicle-parameters-form-component
          :trailerVehicleType="trailerVehicleType"
          @deleteTrailer="deleteTrailer"
          @addTrailer="addTrailer"
          :errorTruckData="errorTruckData"
          :errorTrailerInformation="errorTrailerInformation"
          :errorFreightAndShippingInformation="errorFreightAndShippingInformation"
          :vehicleParameters="vehicleParameters"
      />
    </section>
    <section v-if="current === 4">
      <vehicle-parameters-load-form-component
          :errorVehicleParametersLoad="errorVehicleParametersLoad"
          :vehicleParametersLoad="vehicleParametersLoad"/>
    </section>
    <section v-if="current === 5">
      <documents-form-component/>
    </section>
  </div>
</template>

<script src="./step-permission.component.ts"></script>
