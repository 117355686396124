<style src="./personal-information-form.component.scss" lang="scss"></style>

<template>
  <div class="personal-information-form">
    <form class="form">
      <div class="row">
        <div class="col-12 col-lg-4 password-margin" v-if="citizenshipValue === 1 || citizenshipValue === 2">
          <div class="row">
            <div class="col-4 col-md-4">
              <div class="form__group">
                <label
                    class="v-label"> {{ locale.series }}</label>
                <v-select
                    :error="errorPersonalInformation.documentTypeNumber"
                    ref="selectPrefix"
                    @select="selectedDocumentType"
                    :options="documentSeriesType"
                    :value="personalInformation.documentTypeNumber"
                />
              </div>
            </div>
            <div class="col-8 col-md-8 pl-0">
              <div class="form__group">
                <label
                    class="v-label">{{ locale.passportNumber }}</label>
                <input
                    :class="{'v-input--error':errorPersonalInformation.documentNumber}"
                    v-model="personalInformation.documentNumber"
                    @input="documentNumberChanged($event.target.value, 'documentNumber')"
                    name="documentNumber"
                    type="text"
                    class="v-input" :placeholder="locale.passportNumberPlaceholder">
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="col-12 col-lg-4 password-margin" v-if="citizenshipValue === 2">-->
        <!--          <div class="row">-->
        <!--            <div class="col-4 col-md-4">-->
        <!--              <div class="form__group">-->
        <!--                <label-->
        <!--                    class="v-label"> {{ locale.series }}</label>-->
        <!--                <v-select-->
        <!--                    ref="selectPrefix"-->
        <!--                    @select="selectedDocumentTypeResident"-->
        <!--                    :options="documentTypeResident"-->
        <!--                    :value="personalInformation.documentTypeResident"-->
        <!--                />-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            <div class="col-8 col-md-8 pl-0">-->
        <!--              <div class="form__group">-->
        <!--                <label-->
        <!--                    class="v-label">{{ locale.passportNumber }}</label>-->
        <!--                <input-->
        <!--                    :class="{'v-input&#45;&#45;error':errorPersonalInformation.documentTypeResidentNumber}"-->

        <!--                    v-model="personalInformation.documentTypeResidentNumber"-->
        <!--                    @input="documentNumberChanged($event.target.value, 'documentTypeResidentNumber')"-->
        <!--                    name="documentNumber"-->
        <!--                    type="text"-->
        <!--                    class="v-input" :placeholder="locale.positionPlaceholder">-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="col-12 col-lg-4" v-if="citizenshipValue === 1 || citizenshipValue === 2">
          <div class="form__group">
            <label class="v-label">{{ locale.fin }}</label>
            <input

                :class="{'v-input--error':errorPersonalInformation.fin}"
                placeholder="1234ABC"
                @input="finChanged($event.target.value, 'fin')" type="text"
                class="v-input" v-model="personalInformation.fin">
          </div>
        </div>
        <!--        <div class="col-12 col-lg-4" v-if="citizenshipValue === 2">-->
        <!--          <div class="form__group">-->
        <!--            <label class="v-label">FIN</label>-->
        <!--            <input-->
        <!--                :class="{'v-input&#45;&#45;error':errorPersonalInformation.finResident}"-->
        <!--                placeholder="1234ABC"-->
        <!--                @input="finChanged($event.target.value, 'finResident' )" type="text"-->
        <!--                class="v-input" v-model="personalInformation.finResident">-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="w-100" v-if="citizenshipValue===2"></div>
        <div class="col-12 col-lg-4" v-if="(citizenshipValue===3) && organizationLegalFormValue===2">
          <div class="form__group">
            <label class="v-label">{{ locale.nameOrganization }}</label>
            <input
                :class="{'v-input--error':errorPersonalInformation.organization}"
                :placeholder="locale.nameOrganizationPlaceholder"
                @input="organizationChanged($event.target.value)" type="text"
                class="v-input" v-model="personalInformation.organization">
          </div>
        </div>
        <div class="w-100 mb-20" v-if="(citizenshipValue===3) && organizationLegalFormValue===2"></div>
        <div class="col-12 col-lg-4 password-margin" v-if="citizenshipValue === 3">
          <div class="form__group">
            <label class="v-label">{{ locale.firstName }}</label>
            <input
                :class="{'v-input--error':errorPersonalInformation.name}"
                :placeholder="locale.firstNamePlaceholder"
                @input="inputChanged($event.target.value, 'name')" type="text"
                class="v-input" v-model="personalInformation.name">
          </div>
        </div>
        <div class="col-12 col-lg-4" v-if="citizenshipValue === 3">
          <div class="form__group">
            <label class="v-label">{{ locale.surname }}</label>
            <input
                :class="{'v-input--error':errorPersonalInformation.surname}"
                :placeholder="locale.surnamePlaceholder"
                @input="inputChanged($event.target.value, 'surname')" type="text"
                class="v-input" v-model="personalInformation.surname">
          </div>
        </div>
        <div class="w-100" v-if="citizenshipValue === 3"></div>
        <div class="col-12 col-lg-4 mt-20" v-if="citizenshipValue === 3">
          <div class="form__group">
            <label class="v-label">{{ locale.passwordSeries }}</label>
            <input
                :class="{'v-input--error':errorPersonalInformation.documentNumber}"
                placeholder="1234ABC"
                @input="passwordSeriesChanged($event.target.value, 'documentNumber')" type="text"
                class="v-input" v-model="personalInformation.documentNumber">
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-20" v-if="citizenshipValue===3 && organizationLegalFormValue===2">
          <div class="form__group">
            <label class="v-label">{{ locale.position }}</label>
            <input
                :class="{'v-input--error':errorPersonalInformation.position}"
                :placeholder="locale.positionPlaceholder"
                @input="positionChanged($event.target.value)" type="text"
                class="v-input" v-model="personalInformation.position">
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-8 mt-20" v-if="citizenshipValue===3">
          <div class="form__group">
            <label class="v-label">{{ locale.address }}</label>
            <textarea
                :class="{'v-input--error':errorPersonalInformation.address}"
                :placeholder="locale.addressPlaceholder"
                @input="addressChanged($event.target.value, 'address')" type="text"
                v-model="personalInformation.address" class="v-textarea"></textarea>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-20" v-if="citizenshipValue===2">
          <div class="form__group" :class="{'form__group-error':errorPersonalInformation.dateOfBirth}">
            <label class="v-label">{{ locale.dateOfBirth }}</label>
            <datepicker
                v-model="personalInformation.dateOfBirth"
                :locale="dataLang"
                :upperLimit="dateLimit"/>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.phoneNumber }}</label>
            <input
                :class="{'v-input--error':errorPersonalInformation.phone}"
                @keyup="deleteNumber($event, 'phone')"
                @keydown="deleteNumber($event,'phone')"
                v-mask="['+994(##)-###-##-##', '+994(##)-###-##-##']"
                @input="phoneChanged($event.target.value, 'phone')" type="text"
                class="v-input" v-model="personalInformation.phone">
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.mail }}</label>
            <input
                :class="{'v-input--error':errorPersonalInformation.email}"
                :placeholder="locale.emailOrganizationPlaceholder"
                @input="emailChanged($event.target.value)" type="text"
                class="v-input" v-model="personalInformation.email">
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mt-20"
             v-if="(citizenshipValue===1 || citizenshipValue===2) && organizationLegalFormValue===2">
          <div class="form__group">
            <label class="v-label">{{ locale.tin }}</label>
            <input
                :class="{'v-input--error':errorPersonalInformation.voen}"
                :placeholder="locale.tinPlaceholder"
                @input="tinChanged($event.target.value)" type="text"
                class="v-input" v-model="personalInformation.voen">
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-20"
             v-if="(citizenshipValue===1 || citizenshipValue===2) && organizationLegalFormValue===2">
          <div class="form__group">
            <label class="v-label">{{ locale.nameOrganization }}</label>
            <input
                :class="{'v-input--error':errorPersonalInformation.organization}"
                :placeholder="locale.nameOrganizationPlaceholder"
                @input="organizationChanged($event.target.value)" type="text"
                class="v-input" v-model="personalInformation.organization">
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mt-20"
             v-if="(citizenshipValue===1 || citizenshipValue===2) && organizationLegalFormValue===2">
          <div class="form__group">
            <label class="v-label">{{ locale.position }}</label>
            <input
                :class="{'v-input--error':errorPersonalInformation.position}"
                :placeholder="locale.positionPlaceholder"
                @input="positionChanged($event.target.value)" type="text"
                class="v-input" v-model="personalInformation.position">
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-8" v-if="organizationLegalFormValue ===2 && legalPersonCarrier===false">
          <div class="row">
            <div class="col-12">
              <v-divider class="my-40"/>
              <div class="title"> {{ locale.carrierInfo }}</div>
            </div>
            <div class="col-12 col-lg-6 mt-20">
              <div class="form__group">
                <label class="v-label">{{ locale.firstName }}</label>
                <input
                    :class="{'v-input--error':errorPersonalInformation.carrierName}"
                    :placeholder="locale.firstNamePlaceholder"
                    @input="inputChanged($event.target.value, 'carrierName')" type="text"
                    class="v-input" v-model="personalInformation.carrierName">
              </div>
            </div>
            <div class="col-12 col-lg-6 mt-20">
              <div class="form__group">
                <label class="v-label">{{ locale.phoneNumber }}</label>
                <input
                    :class="{'v-input--error':errorPersonalInformation.carrierPhone}"
                    @keyup="deleteNumber($event, 'carrierPhone')"
                    @keydown="deleteNumber($event,'carrierPhone')"
                    v-mask="['+994(##)-###-##-##', '+994(##)-###-##-##']"
                    @input="phoneChanged($event.target.value, 'carrierPhone')" type="text"
                    class="v-input" v-model="personalInformation.carrierPhone">
              </div>
            </div>
            <div class="col-12 mt-20">
              <div class="form__group">
                <label class="v-label">{{ locale.address }}</label>
                <textarea
                    :class="{'v-input--error':errorPersonalInformation.carrierAddress}"
                    :placeholder="locale.addressPlaceholder"
                    @input="addressChanged($event.target.value, 'carrierAddress')" type="text"
                    v-model="personalInformation.carrierAddress" class="v-textarea"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./personal-information-form.component.ts"></script>
