import 'reflect-metadata';
import {container, injectable} from 'tsyringe';
import {store} from '@/store';

@injectable()
export class PermissionMovementServiceSandbox {
    private store = store;

    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }

    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }

    getCitizenship(): any {
        return this.store.dispatch('getCitizenship');
    }

    getCommonCitizenship(): any {
        return this.store.dispatch('getCitizenship');
    }


    getDocumentSeriesType(): any {
        return this.store.dispatch('getDocumentSeriesType');
    }

    organPersonType(): any {
        return this.store.dispatch('organPersonType');
    }

    getCommonOrganPersonType(): any {
        return this.store.dispatch('organPersonType');
    }

    getTrailerVehicleType(): any {
        return this.store.dispatch('getTrailerVehicleType');
    }

    postAddAppeal(payload: any) {

        return this.store.dispatch('postAddAppeal', payload);
    }

    postAddAppealVehicleInfo(payload: any) {
        return this.store.dispatch('postAddAppealVehicleInfo', payload);
    }

    postAddAppealFile(payload: any) {
        return this.store.dispatch('postAddAppealFile', payload);
    }

    postConfirmAppeal(payload: any) {
        return this.store.dispatch('postConfirmAppeal', payload);
    }

}