<style src="./documents-form.component.scss" lang="scss"></style>

<template>
  <div class="documents-form">
    <form class="form">
      <div class="row">
        <div class="col-12">
          <label class="v-label">{{locale.otherFile}}</label>
          <file-input-component
              :multiple="true" @file="selectFile"/>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./documents-form.component.ts"></script>
