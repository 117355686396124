export class PostAddAppealVehicleInfoModel {
    public appealNumber: string | null = null;
    public truckBrand: string | null = null;
    public truckModel: string | null = null;
    public truckRegistrationMark: string | null = null;
    public regCertNumber: string | null = null;
    public totalLength: string | null = null;
    public totalWidth: string | null = null;
    public heightFromLand: string | null = null;
    public totalWeight: string | null = null;
    public weightForAxises: string | null = null;
    public lengthBetweenAxises: string | null = null;
    public transportCargoType: string | null = null;
    public transportCargoWeight: string | null = null;
    public transportDay: string | null = null;
    public appealVehicleInfoTrailers: string | null = null;
    public cargoAxisSchemeFile: string | null = null;

    constructor(vehicleParameters: any, appealNumber: any, vehicleParametersLoad: any) {
        this._setAppealNumber(appealNumber);
        this._setTruckBrand(vehicleParameters);
        this._setTruckModel(vehicleParameters);
        this._setTruckRegistrationMark(vehicleParameters);
        this._setRegCertNumber(vehicleParameters);
        this._setTotalLength(vehicleParametersLoad);
        this._setTotalWidth(vehicleParametersLoad);
        this._setHeightFromLand(vehicleParametersLoad);
        this._setTotalWeight(vehicleParametersLoad);
        this._setWeightForAxises(vehicleParametersLoad);
        this._setLengthBetweenAxises(vehicleParametersLoad);
        this._setTransportCargoType(vehicleParameters);
        this._setTransportCargoWeight(vehicleParameters);
        this._setTransportDay(vehicleParameters);
        this._setAppealVehicleInfoTrailers(vehicleParameters);
        this._setCargoAxisSchemeFile(vehicleParameters);
    }

    private _setAppealNumber(appealNumber: any) {
        this.appealNumber = appealNumber;
    }

    private _setTruckBrand({truckData}: any) {
        this.truckBrand = truckData?.brand;
    }

    private _setTruckModel({truckData}: any) {
        this.truckModel = truckData?.model;
    }

    private _setTruckRegistrationMark({truckData}: any) {
        this.truckRegistrationMark = truckData?.stateRegistrationMark;
    }

    private _setRegCertNumber({truckData}: any) {
        this.regCertNumber = truckData?.registrationCertificate;
    }

    private _setTotalLength({lengthMeters}: any) {
        this.totalLength = lengthMeters;
    }

    private _setTotalWidth({widthMeters}: any) {
        this.totalWidth = widthMeters;
    }

    private _setHeightFromLand({heightAboveGround}: any) {
        this.heightFromLand = heightAboveGround;
    }

    private _setTotalWeight({totalMass}: any) {
        this.totalWeight = totalMass;
    }

    private _setWeightForAxises({axleLoad}: any) {
        this.weightForAxises = axleLoad;
    }

    private _setLengthBetweenAxises({ distanceBetweenAxles}: any) {
        this.lengthBetweenAxises = distanceBetweenAxles;
    }

    private _setTransportCargoType({freightAndShippingInformation}: any) {
        this.transportCargoType = freightAndShippingInformation.typeCargoTransported;
    }

    private _setTransportCargoWeight({freightAndShippingInformation}: any) {
        this.transportCargoWeight = freightAndShippingInformation.loadWeight;
    }

    private _setTransportDay({freightAndShippingInformation}: any) {
        this.transportDay = freightAndShippingInformation.shippingTime;
    }

    private _setAppealVehicleInfoTrailers({trailerInformation}: any) {
        this.appealVehicleInfoTrailers = trailerInformation.map((item: any) => {
            return new TrailerInformation(item);
        });
    }

    private _setCargoAxisSchemeFile({truckData}: any) {
        this.cargoAxisSchemeFile = truckData.file;
    }
}

class TrailerInformation {
    public trailerBrand: string | null = null;
    public trailerModel: string | null = null;
    public trailerRegistrationMark: string | null = null;
    public regCertNumber: string | null = null;
    public trailerVehicleTypeId: number | null = null;

    constructor(item: any) {
        this._setTrailerBrand(item);
        this._setTrailerModel(item);
        this._setTrailerRegistrationMark(item);
        this._setRegCertNumber(item);
        this._setTrailerVehicleTypeId(item);
    }

    private _setTrailerBrand({brand}: any) {
        this.trailerBrand = brand;
    }

    private _setTrailerModel({model}: any) {
        this.trailerModel = model;
    }

    private _setTrailerRegistrationMark({stateRegistrationMark}: any) {
        this.trailerRegistrationMark = stateRegistrationMark;
    }

    private _setRegCertNumber({registrationCertificate}: any) {
        this.regCertNumber = registrationCertificate;
    }

    private _setTrailerVehicleTypeId({typeVehicle}: any) {
        this.trailerVehicleTypeId = typeVehicle;
    }
}