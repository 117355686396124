import 'reflect-metadata';
import {mixins, Options} from 'vue-class-component';
import {container} from 'tsyringe';
import {store} from '@/store';
import {
    PermissionMovementServiceSandbox
} from '@/pages/permission-movement-service/sandbox/permission-movement-service.sandbox';
import {locale} from '@/assets/db/db.conf.ts';
import {IServiceCommon} from '@/types/service-common';
import StepsCardComponent from '@/pages/services/components/steps-card/steps-card.component.vue';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import StepPermissionComponent
    from '@/pages/permission-movement-service/components/step-permission/step-permission.component.vue';
import {IErrorPersonalInformation} from '@/pages/permission-movement-service/types/errorPersonalInformation';
import {
    IVehicleParametersLoadError,
} from '@/pages/permission-movement-service/types/vehicleParametersLoad-error';
import {ITruckDataError} from '@/pages/permission-movement-service/types/truck-data-error';
import {ITrailerInformation} from '@/pages/permission-movement-service/types/trailer-information';
import {
    IFreightAndShippingInformation
} from '@/pages/permission-movement-service/types/freight-and-shipping-information';
import {PostAddAppealModel} from '@/pages/permission-movement-service/models/post-add-appeal.model';
import {CreateFormData} from '@/core/mixin/mixin';
import {
    PostAddAppealVehicleInfoModel
} from '@/pages/permission-movement-service/models/post-add-appeal-vehicle-info.model';
import {PostAddAppealFileModel} from '@/pages/permission-movement-service/models/post-add-appeal-file.model';
import {IServiceHeader} from '@/types/service-header';
import {Login} from '@/core/configs/api';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'permission-movement-service-component',
    components: {
        StepsCardComponent,
        CustomerInfoComponent,
        ServiceNameComponent,
        StepPermissionComponent,
    },
    provide() {
        return {
            citizenship: this.citizenship,
            organizationLegalForm: this.organizationLegalForm,
            stepOneValidation: this.stepOneValidation,
            citizenshipValue: this.citizenshipValue,
            organizationLegalFormValue: this.organizationLegalFormValue,
            personalInformation: this.personalInformation,
            legalPersonCarrier: this.legalPersonCarrier,
            documentSeriesType: this.documentSeriesType,
            trailerVehicleType: this.trailerVehicleType
        };
    },
    watch: {
        userData: [
            {
                handler: 'changeUserData',
                immediate: false,
                deep: false,
            },
        ]
    }

})

export default class PermissionMovementServiceComponent extends mixins(CreateFormData, Router) {
    public req = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
    public step: number = 5;
    public current: number = 1;
    public check = false;
    public service = 1150;
    public sandbox: PermissionMovementServiceSandbox = container.resolve(PermissionMovementServiceSandbox);
    public store = store;
    public locale$: any = locale;
    public locale: any = null;
    public localeService: any = null;
    public $refs!: {
        modal: {
            openModal: () => void;
            closeModal: () => void
        },
    };
    public errorPersonalInformation: IErrorPersonalInformation = {
        documentNumber: false,
        documentTypeNumber: false,
        fin: false,
        phone: false,
        email: false,
        tin: false,
        organization: false,
        position: false,
        dateOfBirth: false,
        name: false,
        surname: false,
        passportSeriesAndNumber: false,
        gender: false,
        bloodGroup: false,
        address: false,
        carrierName: false,
        carrierPhone: false,
        carrierAddress: false,
        documentTypeResident: false,
        documentTypeResidentNumber: false,
        finResident: false
    };
    public errorVehicleParametersLoad: IVehicleParametersLoadError = {
        lengthMeters: false,
        heightAboveGround: false,
        axleLoad: false,
        widthMeters: false,
        totalMass: false,
        distanceBetweenAxles: false,
    };
    public errorTruckData: ITruckDataError = {
        brand: false,
        model: false,
        stateRegistrationMark: false,
        registrationCertificate: false,
        file: false,
    };
    public errorTrailerInformation: ITrailerInformation[] = [
        {
            brand: false,
            model: false,
            typeVehicle: false,
            stateRegistrationMark: false,
            registrationCertificate: false,
        }
    ];
    public errorFreightAndShippingInformation: IFreightAndShippingInformation = {
        typeCargoTransported: false,
        loadWeight: false,
        shippingTime: false,
    };
    public stepOneValue = {
        citizenship: '',
        organizationLegalForm: ''
    };
    public stepOneValidation = {
        citizenship: false,
        organizationLegalForm: false
    };

    // public serviceHeader =
    //     [
    //         {
    //             staticImg: true,
    //             icon: 'info2.svg',
    //             id: 1,
    //             serviceHeaderFiles: [],
    //             serviceHeaderUrls: [],
    //             title: 'Təlimat',
    //         },
    //         {
    //             staticImg: true,
    //             id: 4,
    //             icon: 'setting-name.svg',
    //             serviceHeaderFiles: [],
    //             serviceHeaderUrls: [],
    //             title: 'Reqlament',
    //         },
    //         {
    //             staticImg: true,
    //             icon: 'info-name.svg',
    //             id: 5,
    //             serviceHeaderFiles: [],
    //             serviceHeaderUrls: [],
    //             title: 'Qanunvericilik',
    //         }
    //     ];

    get citizenshipValue() {
        return this.store.getters._getCitizenshipValue;
    }


    get organizationLegalFormValue() {
        return this.store.getters._getOrganizationLegalFormValue;
    }

    get legalPersonCarrier() {
        return this.store.getters._getLegalPersonCarrier;
    }

    get personalInformation() {
        return this.store.getters._getPersonalInformation;
    }

    get documents() {
        return this.store.getters._getDocuments;
    }

    get vehicleParameters() {
        return this.store.getters._getVehicleParameters;
    }

    get vehicleParametersLoad() {
        return this.store.getters._getVehicleParametersLoad;
    }

    get citizenship() {
        return this.store.getters._getCitizenship;
    }

    get organizationLegalForm() {
        return this.store.getters._getOrganPersonType;
    }

    get userDataAze() {
        return this.store.getters._getUserData_Aze;
    }

    get documentSeriesType() {
        return this.store.getters._getDocumentSeriesType;
    }

    get trailerVehicleType() {
        return this.store.getters._getTrailerVehicleType;
    }

    get appealId() {
        return this.store.getters._getAppealId;
    }


    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    get userData() {
        this.changeUserData();
        return store.getters._getUserFullData;
    }

    get stepDescription() {
        return [
            {
                number: 1,
                description: this.localeService?.step1,
            },
            {
                number: 2,
                description: this.localeService?.step2,
            },
            {
                number: 3,
                description: this.localeService?.step3,
            },
            {
                number: 4,
                description: this.localeService?.step4,
            },
            {
                number: 5,
                description: this.localeService?.step5,
            },
        ];
    }

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.elements) {
                this.locale = res.elements;
                this.localeService = res.permissionMovementService;
            }
        });
    }

    public mounted() {
        this.sandbox.getCitizenship();
        this.sandbox.getTrailerVehicleType();
        this.sandbox.organPersonType();
        this.sandbox.getDocumentSeriesType();
        this.getServiceData();
    }

    public beforeUnmount() {
        this.store.commit('SET_RESET_PERMISSION_FINAL');
        this.store.commit('SET_RESET_ORGANIZATION_LEGAL_FORM_VALUE');
        this.store.commit('SET_RESET_CITIZENSHIP_VALUE');
    }

    public changeUserData() {
        if (this.citizenshipValue === 0 || this.citizenshipValue === 1) {
            if (store.getters._getUserFullData.documentType) {
                if (store.getters._getUserFullData.documentType === 'AZE') {
                    this.store.commit('SET_PERSONAL_ORGANIZATION', {
                        type: 'documentTypeNumber',
                        value: 1
                    });
                }
                if (store.getters._getUserFullData.documentType === 'AA') {
                    this.store.commit('SET_PERSONAL_ORGANIZATION', {
                        type: 'documentTypeNumber',
                        value: 2
                    });
                }
                if (store.getters._getUserFullData.documentType === 'DY') {
                    this.store.commit('SET_PERSONAL_ORGANIZATION', {
                        type: 'documentTypeNumber',
                        value: 4
                    });
                }
                if (store.getters._getUserFullData.documentType === 'MY') {
                    this.store.commit('SET_PERSONAL_ORGANIZATION', {
                        type: 'documentTypeNumber',
                        value: 3
                    });
                }
            }
            if (store.getters._getUserFullData.documentNumber) {
                this.store.commit('SET_PERSONAL_ORGANIZATION', {
                    type: 'documentNumber',
                    value: store.getters._getUserFullData.documentNumber
                });
            }
            if (store.getters._getUserFullData?.pin) {
                this.store.commit('SET_PERSONAL_ORGANIZATION', {
                    type: 'fin',
                    value: store.getters._getUserFullData?.pin
                });
                if (store.getters._getUserFullData.pin.length === 7) {
                    this.store.commit('SET_CITIZENSHIP_VALUE', 1);
                } else {
                    this.store.commit('SET_CITIZENSHIP_VALUE', 2);
                }
            }
        }
    }

    public checkStepOneValidation() {
        if (this.citizenshipValue) {
            this.stepOneValidation.citizenship = false;
        } else {
            this.stepOneValidation.citizenship = true;
        }
        if (this.organizationLegalFormValue) {
            this.stepOneValidation.organizationLegalForm = false;
        } else {
            this.stepOneValidation.organizationLegalForm = true;
        }
    }

    public validation(type: string) {
        if (this.personalInformation[`${type}`]) {
            this.errorPersonalInformation[`${type}`] = false;
        } else {
            this.errorPersonalInformation[`${type}`] = true;
        }
        if (type === 'phone' || type === 'carrierPhone') {
            if (this.personalInformation.phone.length === 18) {
                this.errorPersonalInformation[`${type}`] = false;
            } else {
                this.errorPersonalInformation[`${type}`] = true;
            }
        }
        if (type === 'email') {
            if (this.req.test(this.personalInformation.email)) {
                this.errorPersonalInformation.email = false;
            } else {
                this.errorPersonalInformation.email = true;
            }
        }

        if (type === 'tin') {
            if (this.personalInformation.tin.length === 10) {
                this.errorPersonalInformation.tin = false;
            } else this.errorPersonalInformation.tin = true;
        }
    }

    public validationVehicleParametersFormTruckData(type: string) {
        if (this.vehicleParameters.truckData[`${type}`]) {
            this.errorTruckData[`${type}`] = false;
        } else {
            this.errorTruckData[`${type}`] = true;
        }
    }

    public validationTrailerInformation() {
        this.vehicleParameters.trailerInformation.forEach((item: any, index: any) => {
            if (item.brand) {
                this.errorTrailerInformation[index].brand = false;
            } else {
                this.errorTrailerInformation[index].brand = true;
            }
            if (item.model) {
                this.errorTrailerInformation[index].model = false;
            } else {
                this.errorTrailerInformation[index].model = true;
            }
            if (item.typeVehicle) {
                this.errorTrailerInformation[index].typeVehicle = false;
            } else {
                this.errorTrailerInformation[index].typeVehicle = true;
            }
            if (item.stateRegistrationMark) {
                this.errorTrailerInformation[index].stateRegistrationMark = false;
            } else {
                this.errorTrailerInformation[index].stateRegistrationMark = true;
            }
            if (item.registrationCertificate) {
                this.errorTrailerInformation[index].registrationCertificate = false;
            } else {
                this.errorTrailerInformation[index].registrationCertificate = true;
            }


        });
    }

    public validationFreightAndShippingInformation(type: string) {
        if (this.vehicleParameters.freightAndShippingInformation[`${type}`]) {
            this.errorFreightAndShippingInformation[`${type}`] = false;
        } else {
            this.errorFreightAndShippingInformation[`${type}`] = true;
        }
    }

    public validationThirdStep() {
        this.validationVehicleParametersFormTruckData('brand');
        this.validationVehicleParametersFormTruckData('model');
        this.validationVehicleParametersFormTruckData('typeVehicle');
        this.validationVehicleParametersFormTruckData('stateRegistrationMark');
        this.validationVehicleParametersFormTruckData('registrationCertificate');
        this.validationVehicleParametersFormTruckData('file');
        this.validationFreightAndShippingInformation('typeCargoTransported');
        this.validationFreightAndShippingInformation('loadWeight');
        this.validationFreightAndShippingInformation('shippingTime');
        this.validationTrailerInformation();
    }


    public validationInformationCarrier() {
        this.validation('carrierName');
        this.validation('carrierPhone');
        this.validation('carrierAddress');
    }

    public validationInformationFirst() {
        this.validation('documentNumber');
        this.validation('fin');
        this.validation('phone');
        this.validation('email');
        this.validation('documentTypeNumber');
    }

    public validationInformationResidentFirst() {
        this.validation('documentNumber');
        this.validation('fin');
        this.validation('phone');
        this.validation('email');
        this.validation('dateOfBirth');
        this.validation('documentTypeNumber');

    }

    public validationInformationSecond() {
        this.validation('tin');
        this.validation('organization');
        this.validation('position');
    }


    public validationInformationForeignCitizenFirst() {
        this.validation('name');
        this.validation('surname');
        this.validation('passportSeriesAndNumber');
        this.validation('address');
        this.validation('phone');
        this.validation('email');
    }

    public validationInformationForeignCitizenSecond() {
        this.validation('organization');
        this.validation('position');
    }

    public checkStepSecondValidation() {
        if (this.citizenshipValue === 1 && this.organizationLegalFormValue === 1) {
            this.validationInformationFirst();
        }
        if ((this.citizenshipValue === 1 && this.organizationLegalFormValue === 2) && this.legalPersonCarrier) {
            this.validationInformationFirst();
            this.validationInformationSecond();
        }
        if ((this.citizenshipValue === 1 && this.organizationLegalFormValue === 2) && !this.legalPersonCarrier) {
            this.validationInformationFirst();
            this.validationInformationSecond();
            this.validationInformationCarrier();
        }

        if (this.citizenshipValue === 2 && this.organizationLegalFormValue === 1) {
            this.validationInformationResidentFirst();
        }

        if ((this.citizenshipValue === 2 && this.organizationLegalFormValue === 2) && this.legalPersonCarrier) {
            this.validationInformationResidentFirst();
            this.validationInformationSecond();
        }
        if ((this.citizenshipValue === 2 && this.organizationLegalFormValue === 2) && !this.legalPersonCarrier) {
            this.validationInformationResidentFirst();
            this.validationInformationSecond();
            this.validationInformationCarrier();
        }

        if (this.citizenshipValue === 3 && this.organizationLegalFormValue === 1) {
            this.validationInformationForeignCitizenFirst();
        }

        if ((this.citizenshipValue === 3 && this.organizationLegalFormValue === 2) && this.legalPersonCarrier) {
            this.validationInformationForeignCitizenFirst();
            this.validationInformationForeignCitizenSecond();

        }
        if ((this.citizenshipValue === 3 && this.organizationLegalFormValue === 2) && !this.legalPersonCarrier) {
            this.validationInformationForeignCitizenFirst();
            this.validationInformationForeignCitizenSecond();
            this.validationInformationCarrier();
        }
    }

    public checkValidationFirstStep() {
        this.checkStepOneValidation();
        if (!this.stepOneValidation.organizationLegalForm && !this.stepOneValidation.citizenship) {
            this.current = 2;
        }
    }

    public validationFourthStep() {
        this.validationVehicleParametersLoad('');
        this.validationVehicleParametersLoad('');
        this.validationVehicleParametersLoad('');
        this.validationVehicleParametersLoad('');
        this.validationVehicleParametersLoad('');
    }

    public validationVehicleParametersLoad(type: string) {
        if (this.vehicleParametersLoad[`${type}`]?.trim()) {
            this.errorVehicleParametersLoad[`${type}`] = false;
        } else {
            this.errorVehicleParametersLoad[`${type}`] = true;
        }
    }

    public postAddAppeal() {
        let data = new PostAddAppealModel(this.personalInformation, this.citizenshipValue, this.organizationLegalFormValue, this.legalPersonCarrier);
        this.sandbox.postAddAppeal(data).then((res: any) => {
            if (this.appealId) {
                this.current = 3;
            }
        });
    }

    public checkValidationSecondStep() {
        this.checkStepSecondValidation();
        // Citizen of Azerbaijan
        if (this.citizenshipValue === 1 && this.organizationLegalFormValue === 1) {
            if (!this.errorPersonalInformation.dateOfBirth &&
                !this.errorPersonalInformation.fin
                && !this.errorPersonalInformation.phone
                && !this.errorPersonalInformation.email
                && !this.errorPersonalInformation.documentNumber
                && !this.errorPersonalInformation.documentTypeNumber
            ) {
                this.postAddAppeal();
            }
        }
        if (this.citizenshipValue === 1 && this.organizationLegalFormValue === 2) {
            if (!this.errorPersonalInformation.fin
                && !this.errorPersonalInformation.phone
                && !this.errorPersonalInformation.email
                && !this.errorPersonalInformation.documentNumber
                && !this.errorPersonalInformation.tin
                && !this.errorPersonalInformation.organization
                && !this.errorPersonalInformation.position
                && !this.errorPersonalInformation.documentTypeNumber
            ) {
                this.postAddAppeal();
            }
        }
        if (this.citizenshipValue === 1 && this.organizationLegalFormValue === 2 && !this.legalPersonCarrier) {
            if (!this.errorPersonalInformation.fin
                && !this.errorPersonalInformation.documentTypeNumber
                && !this.errorPersonalInformation.phone
                && !this.errorPersonalInformation.email
                && !this.errorPersonalInformation.documentNumber
                && !this.errorPersonalInformation.tin
                && !this.errorPersonalInformation.organization
                && !this.errorPersonalInformation.position
                && !this.errorPersonalInformation.carrierAddress
                && !this.errorPersonalInformation.carrierName
                && !this.errorPersonalInformation.carrierPhone
            ) {
                this.postAddAppeal();
            }
        }
        // Having a temporary (permanent) residence
        if (this.citizenshipValue === 2 && this.organizationLegalFormValue === 1) {
            if (!this.errorPersonalInformation.fin
                && !this.errorPersonalInformation.documentTypeNumber
                && !this.errorPersonalInformation.documentNumber
                && !this.errorPersonalInformation.email
                && !this.errorPersonalInformation.phone
            ) {
                this.postAddAppeal();
            }
        }
        if (this.citizenshipValue === 2 && this.organizationLegalFormValue === 2) {
            if (!this.errorPersonalInformation.fin
                && !this.errorPersonalInformation.documentTypeNumber
                && !this.errorPersonalInformation.documentNumber
                && !this.errorPersonalInformation.email
                && !this.errorPersonalInformation.phone
                && !this.errorPersonalInformation.tin
                && !this.errorPersonalInformation.organization
                && !this.errorPersonalInformation.position
            ) {

                this.postAddAppeal();
            }
        }
        if (this.citizenshipValue === 2 && this.organizationLegalFormValue === 2 && !this.legalPersonCarrier) {
            if (!this.errorPersonalInformation.fin
                && !this.errorPersonalInformation.documentTypeNumber
                && !this.errorPersonalInformation.documentNumber
                && !this.errorPersonalInformation.email
                && !this.errorPersonalInformation.phone
                && !this.errorPersonalInformation.tin
                && !this.errorPersonalInformation.organization
                && !this.errorPersonalInformation.position
                && !this.errorPersonalInformation.carrierPhone
                && !this.errorPersonalInformation.carrierName
                && !this.errorPersonalInformation.carrierAddress
            ) {
                this.postAddAppeal();
            }
        }
        // Foreign citizen
        if (this.citizenshipValue === 3 && this.organizationLegalFormValue === 1) {
            if (!this.errorPersonalInformation.name
                && !this.errorPersonalInformation.surname
                && !this.errorPersonalInformation.documentNumber
                && !this.errorPersonalInformation.address
                && !this.errorPersonalInformation.phone
                && !this.errorPersonalInformation.email
            ) {
                this.postAddAppeal();
            }
        }
        if (this.citizenshipValue === 3 && this.organizationLegalFormValue === 2) {
            if (!this.errorPersonalInformation.name
                && !this.errorPersonalInformation.surname
                && !this.errorPersonalInformation.documentNumber
                && !this.errorPersonalInformation.address
                && !this.errorPersonalInformation.phone
                && !this.errorPersonalInformation.email
                && !this.errorPersonalInformation.organization
                && !this.errorPersonalInformation.position
            ) {
                this.postAddAppeal();
            }
        }
        if (this.citizenshipValue === 3 && this.organizationLegalFormValue === 2 && !this.legalPersonCarrier) {
            if (!this.errorPersonalInformation.name
                && !this.errorPersonalInformation.surname
                && !this.errorPersonalInformation.documentNumber
                && !this.errorPersonalInformation.address
                && !this.errorPersonalInformation.phone
                && !this.errorPersonalInformation.email
                && !this.errorPersonalInformation.organization
                && !this.errorPersonalInformation.position
                && !this.errorPersonalInformation.carrierAddress
                && !this.errorPersonalInformation.carrierPhone
                && !this.errorPersonalInformation.carrierName
            ) {
                this.postAddAppeal();
            }
        }

    }


    public checkValidationThirdStep() {
        this.validationThirdStep();
        let trailerInformation = this.checkValidationTrailerInformation();
        if (!this.errorFreightAndShippingInformation.shippingTime
            && !this.errorFreightAndShippingInformation.loadWeight
            && !this.errorFreightAndShippingInformation.typeCargoTransported
            && !this.errorTruckData.brand
            && !this.errorTruckData.model
            && !this.errorTruckData.stateRegistrationMark
            && !this.errorTruckData.registrationCertificate
            && !this.errorTruckData.file
            && !trailerInformation.brand
            && !trailerInformation.model
            && !trailerInformation.typeVehicle
            && !trailerInformation.stateRegistrationMark
            && !trailerInformation.registrationCertificate
        ) {
            this.current = 4;
        }
    }

    public checkValidationTrailerInformation() {
        let common = {
            brand: false,
            model: false,
            typeVehicle: false,
            stateRegistrationMark: false,
            registrationCertificate: false,
        };
        this.errorTrailerInformation.forEach((item: any) => {
            if (item.brand) {
                common.brand = true;
            }
            if (item.model) {
                common.brand = true;
            }
            if (item.typeVehicle) {
                common.brand = true;
            }
            if (item.stateRegistrationMark) {
                common.brand = true;
            }
            if (item.registrationCertificate) {
                common.brand = true;
            }
        });
        return common;
    }

    public checkValidationFourthStep() {
        this.validationVehicleParametersLoad('lengthMeters');
        this.validationVehicleParametersLoad('heightAboveGround');
        this.validationVehicleParametersLoad('axleLoad');
        this.validationVehicleParametersLoad('widthMeters');
        this.validationVehicleParametersLoad('totalMass');
        this.validationVehicleParametersLoad('distanceBetweenAxles');
        if (!this.errorVehicleParametersLoad.lengthMeters
            && !this.errorVehicleParametersLoad.heightAboveGround
            && !this.errorVehicleParametersLoad.axleLoad
            && !this.errorVehicleParametersLoad.widthMeters
            && !this.errorVehicleParametersLoad.totalMass
            && !this.errorVehicleParametersLoad.distanceBetweenAxles
        ) {
            this.postAddAppealVehicleInfo();
            // this.current = 5;
        }
    }

    public postAddAppealVehicleInfo() {
        let data = new PostAddAppealVehicleInfoModel(this.vehicleParameters, this.appealId, this.vehicleParametersLoad);
        let formData = this.createFormData(data);
        this.sandbox.postAddAppealVehicleInfo(formData).then((res: any) => {
            if (res.status === 200) {
                this.current = 5;
            }
        });
    }

    public incrementCurrent() {
        switch (this.current) {
            case 1:
                this.checkValidationFirstStep();
                break;
            case 2:
                this.checkValidationSecondStep();
                break;
            case 3 :
                this.checkValidationThirdStep();
                break;
            case 4 :
                this.checkValidationFourthStep();
                break;
            case 5:
                this.postDocument();
                break;
        }
    }

    public postDocument() {
        if (this.documents?.length > 0) {
            let data = new PostAddAppealFileModel(this.appealId, this.documents);
            let formData = new FormData();
            formData.append('appealNumber', `${data.appealNumber}`);
            data.files?.forEach((item: any) => {
                formData.append('Files', item);
            });
            this.sandbox.postAddAppealFile(formData).then((res) => {
                if (res.status === 200) {
                    this.sandbox.postConfirmAppeal(this.appealId).then((res: any) => {
                        if (res.status === 200) {
                            this.openModal();
                        }
                    });
                }
            });
        } else {
            this.sandbox.postConfirmAppeal(this.appealId).then((res: any) => {
                if (res.status === 200) {
                    this.openModal();
                }
            });
        }

    }

    public decrementCurrent(): void {
        if (this.current > 1) {
            if (this.current === 2) {
                this.errorPersonalInformation = {
                    documentNumber: false,
                    documentTypeNumber: false,
                    fin: false,
                    phone: false,
                    email: false,
                    tin: false,
                    organization: false,
                    position: false,
                    dateOfBirth: false,
                    name: false,
                    surname: false,
                    passportSeriesAndNumber: false,
                    gender: false,
                    bloodGroup: false,
                    address: false,
                    carrierName: false,
                    carrierPhone: false,
                    carrierAddress: false,
                    documentTypeResident: false,
                    documentTypeResidentNumber: false,
                    finResident: false
                };
            }
            this.current = this.current - 1;
        }
    }

    public openModal() {
        this.$refs.modal.openModal();
    }

    public closeModal() {
        this.store.commit('SET_RESET_PERMISSION_FINAL');
        this.store.commit('SET_RESET_ORGANIZATION_LEGAL_FORM_VALUE');
        this.store.commit('SET_RESET_CITIZENSHIP_VALUE');
        this.$refs.modal.closeModal();
        this.current = 1;
        this.$router.push({name: 'home'});
    }

    public addTrailer() {
        this.errorTrailerInformation.push(
            {
                brand: false,
                model: false,
                typeVehicle: false,
                stateRegistrationMark: false,
                registrationCertificate: false,
                file: false,
            }
        );
    }

    public deleteTrailer(info: any) {
        this.errorTrailerInformation.splice(info, 1);
    }
}
