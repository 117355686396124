<style src="./vehicle-parameters-form.component.scss" lang="scss"></style>

<template>
  <div class="vehicle-parameters">
    <form class="form">
      <div class="row">
        <div class="col-12 mb-20">
          <div class="title">{{ locale.truckData }}</div>
        </div>
        <div class="col-12 col-lg-4 password-margin">
          <div class="form__group">
            <label class="v-label">{{ locale.brand }}</label>
            <input
                :class="{'v-input--error':errorTruckData.brand}"
                placeholder="Mercedes benz"
                @input="inputChanged($event.target.value, 'brand', 'truckData')" type="text"
                class="v-input" v-model="vehicleParameters.truckData.brand">
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form__group">
            <label class="v-label">{{ locale.model }}</label>
            <input
                :class="{'v-input--error':errorTruckData.model}"
                placeholder="XXXXXX"
                @input="inputChanged($event.target.value, 'model', 'truckData')" type="text"
                class="v-input" v-model="vehicleParameters.truckData.model">
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.stateRegistrationMark }}</label>
            <input
                :class="{'v-input--error':errorTruckData.stateRegistrationMark}"
                :placeholder="locale.stateRegistrationMarkPlaceholder"
                @input="inputChanged($event.target.value, 'stateRegistrationMark', 'truckData')" type="text"
                class="v-input" v-model="vehicleParameters.truckData.stateRegistrationMark">
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.registrationCertificate }}</label>
            <input
                :class="{'v-input--error':errorTruckData.registrationCertificate}"
                :placeholder="locale.registrationCertificatePlaceholder"
                @input="inputChanged($event.target.value, 'registrationCertificate', 'truckData')" type="text"
                class="v-input" v-model="vehicleParameters.truckData.registrationCertificate">
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group">
            <label :class="{'v-label--error':errorTruckData.file}" class="v-label">{{ locale.file }}</label>
            <file-input-component :fileNameStore="vehicleParameters.truckData.file" @file="selectFile"/>
          </div>
        </div>
        <div class="col-12">
          <v-divider class="my-40"/>
          <div class="title mb-20">{{ locale.trailerInformation }}</div>
        </div>
        <div class="col-12 " v-for="(item, index) in vehicleParameters.trailerInformation">
          <div class="row">
            <div class="col-12" v-if="index!==0">
              <v-divider class="my-40"/>
            </div>
            <div class="col-12 trailer-information" v-if="vehicleParameters.trailerInformation.length>1">
              <span @click="deleteTrailerInformationItem(index)" class="trailer-information-delete"></span>
            </div>
            <div class="col-12 col-lg-4 password-margin">
              <div class="form__group">
                <label class="v-label">{{ locale.brand }}</label>
                <input
                    :class="{'v-input--error':errorTrailerInformation[index].brand}"
                    placeholder="Mercedes benz"
                    @input="inputTrailerInformationChanged($event.target.value, 'brand', 'trailerInformation', index)"
                    type="text"
                    class="v-input" v-model="item.brand">
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="form__group">
                <label class="v-label">{{ locale.model }}</label>
                <input
                    :class="{'v-input--error':errorTrailerInformation[index].model}"
                    placeholder="XXXXXX"
                    @input="inputTrailerInformationChanged($event.target.value, 'model', 'trailerInformation',index)"
                    type="text"
                    class="v-input" v-model="item.model">
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col-12 col-lg-4 mt-20">
              <div class="form__group">
                <label class="v-label">{{ locale.typeVehicle }}</label>

                <v-select
                    :error="errorTrailerInformation[index].typeVehicle"
                    ref="selectTypeVehicle"
                    @select="selectTypeVehicle($event, index)"
                    :value="item.typeVehicle"
                    :placeholder="locale.typeCargoTransportedPlaceholder2"
                    :options="trailerVehicleType"/>
              </div>
            </div>
            <div class="col-12 col-lg-4 mt-20">
              <div class="form__group">
                <label class="v-label">{{ locale.stateRegistrationMark }}</label>
                <input
                    :class="{'v-input--error':errorTrailerInformation[index].stateRegistrationMark}"
                    :placeholder="locale.stateRegistrationMarkPlaceholder"
                    @input="inputChanged($event.target.value, 'stateRegistrationMark', 'trailerInformation')"
                    type="text"
                    class="v-input" v-model="item.stateRegistrationMark">
              </div>
            </div>
            <div class="w-100"></div>
            <div class="col-12 col-lg-4 mt-20">
              <div class="form__group">
                <label class="v-label">{{ locale.registrationCertificate }}</label>
                <input

                    :class="{'v-input--error':errorTrailerInformation[index].registrationCertificate}"
                    :placeholder="locale.registrationCertificatePlaceholder"
                    @input="inputTrailerInformationChanged($event.target.value, 'registrationCertificate', 'trailerInformation',index)"
                    type="text"
                    class="v-input" v-model="item.registrationCertificate">
              </div>
            </div>

          </div>
        </div>
        <div class="col-12">
          <div class="add-trailer-information" @click="addTrailerInformation">
            <img src="@/assets/images/icons/add-obj.svg" alt="">
            <span>{{ locale.addTrailerInformation }}</span>
          </div>

        </div>
        <div class="col-12">
          <v-divider class="my-40"/>
          <div class="title mb-20">{{ locale.freightAndShippingInformation }}</div>
        </div>
        <div class="col-12 col-lg-4 password-margin" >
          <div class="form__group">
            <label class="v-label">{{ locale.typeCargoTransported }}</label>
            <input
                :class="{'v-input--error':errorFreightAndShippingInformation.typeCargoTransported}"
                :placeholder="locale.typeCargoTransportedPlaceholder"
                @input="inputChanged($event.target.value, 'typeCargoTransported', 'freightAndShippingInformation')"
                type="text"
                class="v-input" v-model="vehicleParameters.freightAndShippingInformation.typeCargoTransported">

          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="form__group">
            <label class="v-label">{{ locale.loadWeight }}</label>
            <input
                :class="{'v-input--error':errorFreightAndShippingInformation.loadWeight}"
                placeholder="12"
                @input="inputChanged($event.target.value, 'loadWeight', 'freightAndShippingInformation')" type="text"
                class="v-input" v-model="vehicleParameters.freightAndShippingInformation.loadWeight">
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.shippingTime }}</label>
            <input
                :class="{'v-input--error':errorFreightAndShippingInformation.shippingTime}"
                :placeholder="locale.shippingTimePlaceholder"
                @input="inputChanged($event.target.value, 'shippingTime', 'freightAndShippingInformation')" type="text"
                class="v-input" v-model="vehicleParameters.freightAndShippingInformation.shippingTime">
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./vehicle-parameters-form.component.ts"></script>
