import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {locale} from '@/assets/db/db.conf.ts';
import {store} from '@/store';
import FileInputComponent from '@/core/shared/file-input/file-input.component.vue';

@Options({
    name: 'vehicle-parameters-form-component',
    props: {
        vehicleParameters: {
            type: Object,
            require: true
        },
        errorTruckData: {
            type: Object, require: true
        },
        errorTrailerInformation: {
            type: Object, require: true
        },
        errorFreightAndShippingInformation: {
            type: Object, require: true
        },
        trailerVehicleType:{
            type: Array, require: true
        },
    },
    components: {
        FileInputComponent
    }
})

export default class VehicleParametersFormComponent extends Vue {
    public locale$: any = locale;
    public locale: any = null;
    public store = store;

    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.permissionMovementService) {
                this.locale = res.permissionMovementService;
            }
        });
    }

    public addTrailerInformation() {
        this.$emit('addTrailer');
        this.store.commit('SET_TRAILER_INFORMATION');
    }

    public inputTrailerInformationChanged(event: any, name: any, parent: any, index: any) {
        let payload = {
            parent: parent,
            type: name,
            value: event,
            index: index
        };
        this.store.commit('SET_TRAILER_INFORMATION_ITEM', payload);
    }

    public inputChanged(event: any, name: any, parent: any) {
        let payload = {
            parent: parent,
            type: name,
            value: event
        };
        if(name === 'shippingTime') {
            payload = {
                parent: parent,
                type: name,
                value: event.replace(/[^0-9,.]/g, '')
            };
        }
        if ( name === 'loadWeight') {
            payload = {
                parent: parent,
                type: name,
                value: event.replace(/[^0-9,.]/g, '').slice(0, 3)
            };
        }
        this.store.commit('SET_VEHICLE_PARAMETERS', payload);
    }

    public selectTypeVehicle(info: any, index?: any) {
        let payload = {
            type: 'typeVehicle',
            value: info,
            index: index
        };
        this.store.commit('SET_TRAILER_INFORMATION_ITEM', payload);
    }

    public selectFile(info: any) {
        let payload = {
            parent: 'truckData',
            type: 'file',
            value: info
        };
        this.store.commit('SET_VEHICLE_PARAMETERS', payload);
    }

    public deleteTrailerInformationItem(index: any) {
        this.store.commit('SET_DELETE_TRAILER_INFORMATION_ITEM', index);
        this.$emit('deleteTrailer', index);
    }
}
