<style src="./vehicle-parameters-load-form.component.scss" lang="scss"></style>

<template>
  <div class="vehicle-parameters-load">
    <form class="form">
      <div class="row">
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.lengthMeters }}</label>
            <input
                :class="{'v-input--error':errorVehicleParametersLoad.lengthMeters}"
                :placeholder="locale.lengthMetersPlaceholder"
                @input="inputChanged($event.target.value, 'lengthMeters', 'vehicleParametersLoad',2)"
                type="text"
                class="v-input" v-model="vehicleParametersLoad.lengthMeters">
            <div class="form__group-info">Maksimum 99</div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.widthMeters }}</label>
            <input
                step=".00"
                :class="{'v-input--error':errorVehicleParametersLoad.widthMeters}"
                :placeholder="locale.widthMetersPlaceholder"
                @input="inputChanged($event.target.value, 'widthMeters', 'vehicleParametersLoad',2)"
                type="text"
                class="v-input" v-model="vehicleParametersLoad.widthMeters">
            <div class="form__group-info">Maksimum 99</div>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group form__group-details">
            <label class="v-label">{{ locale.heightAboveGround }}</label>
            <input
                :class="{'v-input--error':errorVehicleParametersLoad.heightAboveGround}"
                :placeholder="locale.heightAboveGroundPlaceholder"
                @input="inputChanged($event.target.value, 'heightAboveGround', 'vehicleParametersLoad', 2)"
                type="text"
                class="v-input" v-model="vehicleParametersLoad.heightAboveGround">

            <span class="form__group-details-icon"></span>
            <span class="form__group-details-text">{{locale.heightAboveGroundInfo}}</span>
          </div>
          <div class="form__group-info">Maksimum 99</div>
        </div>
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.totalMass }}</label>
            <input
                :class="{'v-input--error':errorVehicleParametersLoad.totalMass}"
                :placeholder="locale.totalMassPlaceholder"
                @input="inputChanged($event.target.value, 'totalMass', 'vehicleParametersLoad',3)"
                type="text"
                class="v-input" v-model="vehicleParametersLoad.totalMass">
            <div class="form__group-info">Maksimum 999</div>
          </div>
        </div>
        <div class="w-100"></div>
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.axleLoad }}</label>
            <input
                :class="{'v-input--error':errorVehicleParametersLoad.axleLoad}"
                :placeholder="locale.axleLoadPlaceholder"
                @input="inputChanged($event.target.value, 'axleLoad', 'vehicleParametersLoad',3)"
                type="text"
                class="v-input" v-model="vehicleParametersLoad.axleLoad">
            <div class="form__group-info">Maksimum 999</div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-20">
          <div class="form__group">
            <label class="v-label">{{ locale.distanceBetweenAxles }}</label>
            <input
                :class="{'v-input--error':errorVehicleParametersLoad.distanceBetweenAxles}"
                :placeholder="locale.distanceBetweenAxlesPlaceholder"
                @input="inputChanged($event.target.value, 'distanceBetweenAxles', 'vehicleParametersLoad',2)"
                type="text"
                class="v-input" v-model="vehicleParametersLoad.distanceBetweenAxles">
            <div class="form__group-info">Maksimum 99</div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script src="./vehicle-parameters-load-form.component.ts"></script>